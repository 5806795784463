<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      src="@/assets/backgrounds/image.png"
    >
      <v-list>
        <v-layout mt-1 column align-center>
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-img src="@/assets/iconos/worker.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-center white--text">{{
                empleado
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-center white--text">{{
                usuario
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-layout>
      </v-list>
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon class="white--text">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="white--text"
              @click="cerrar_sesion"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app src="@/assets/backgrounds/image.png">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>SPE</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="3">
          <v-card class="card" :loading="loading" mt-5>
            <v-row>
              <div>
                <v-layout mt-6 mb-4 ml-10>
                  <v-flex>
                    <h1 class="display-2">{{ hora }}</h1>
                  </v-flex>
                </v-layout>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12">
          <template>
            <v-toolbar flat class="mb-3">
              <v-toolbar-title style="font-family: Calibri; font-size: 22px">
                ORDENES DE TRABAJO PENDIENTES
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="ordenes"
                :search="search"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="evaluarItem(item)">
                    mdi-checkbox-marked-outline
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Consultar </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-col>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark src="@/assets/backgrounds/image.png">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            </v-toolbar>
            <v-list three-line subheader>
              <v-subheader>Datos de la orden de Producción</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Contador Tiempo</v-list-item-title>
                  <v-list-item-subtitle>
                    <h1 class="display-2">{{ segundos }}</h1>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>No. de Orden</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ orden_trabajo_seleccionada.folio_orden_produccion }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>No. de Parte</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ orden_trabajo_seleccionada.no_parte }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Articulo</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ orden_trabajo_seleccionada.articulo }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Cantidad</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ orden_trabajo_seleccionada.cantidad_faltante }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-data-table
              dense
              :headers="headers_pasos"
              :items="pasos"
              item-key="name"
              class="elevation-1"
            ></v-data-table>
            <v-row align="center" justify="center">
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  :color="color"
                  @click="empezarTiempo()"
                >
                  <v-icon dark>{{ icono }}</v-icon>
                  
                </v-btn>
                <v-btn class="mx-2" fab dark large color="error">
                  <v-icon dark> mdi-exit-to-app </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_form" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Registro de Cantidad Procesada</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Cantidad Procesada"
                      required
                      v-model="cantidad_ok"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field 
                      label="PNC"  
                      v-model="cantidad_pnc" 
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select 
                      :items="tipo_cierre" 
                      v-model="motivo_cierre" 
                      label="Motivo de Cierre"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_form = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="EnviarEvaluacion">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import service from "@/core/Servicio.js";

export default {
  data: () => ({
    drawer: null,
    cerrar_dialogo: false,
    items: [{ title: "Cerrar sesión", icon: "mdi-logout" }],
    empleado: localStorage.getItem("nombre"),
    usuario: localStorage.getItem("usuario"),
    id_orden_trabajo: 0,
    hora: " 18:20:25",
    dialog: false,
    dialog_form: false,
    titulo: "",
    cantidad_ok: null,
    cantidad_pnc: null,
    motivo_cierre: null,
    orden_trabajo_seleccionada: {
      folio_orden_produccion: "",
      no_parte: "",
      articulo: "",
      cantidad_faltante: "",
    },
    search: "",
    icono: " mdi-play",
    trabajando: false,
    color: "primary",
    bandera_contar: false,
    tiempo_realizado: 0,
    segundos: 0,
    tipo_cierre: ['Cambio de Actividades' , 'Termino de Turno' , 'Termino de Actividades' , 'Otros'],
    pasos: [],
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Folio Orden de Producción", value: "folio_orden_produccion" },
      { text: "Folio Orden de Trabajo", value: "folio_orden_trabajo" },
      { text: "Clave de Área", value: "clave_area" },
      { text: "Área", value: "area" },
      { text: "No. Parte", value: "no_parte" },
      { text: "Artículo", value: "articulo" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Cantidad Procesada", value: "cantidad_realizada" },
      { text: "Cantidad Restante", value: "cantidad_faltante" },
      { text: "Acciones", value: "actions" },
    ],
    headers_pasos: [
      { text: "Clave", value: "clave" },
      { text: "Paso", value: "paso" },
      { text: "Area", value: "area" },
      { text: "Tiempo Estimado", value: "tiempo_estimado" },
      //{ text: "Tiempo Total", value: "tiempo_estimado" },
    ],
    ordenes: [],
  }),
  mounted() {
    this.initialize();
  },
  created() {},
  methods: {
    initialize() {
      this.consultar_ordenes_trabajo();
      this.mueveReloj();
    },
    cerrar_sesion() {
      localStorage.setItem("id", null);
      localStorage.setItem("nombre", null);
      localStorage.setItem("usuario", null);
      this.$router.push("/");
    },
    empezarTiempo() {
      if (this.trabajando) {
        this.icono = "mdi-play";
        this.color = "primary";
        this.trabajando = false;
        this.bandera_contar = false;
        this.tiempo_realizado = this.segundos;
       // this.segundos = 0;
        this.dialog_form = true;
      } else {
        this.bandera_contar = true;
        this.color = "warning";
        this.icono = "mdi-stop";
        this.trabajando = true;
        this.segundos = 0;
        this.contarTiempo();
      }
    },
    consultar_ordenes_trabajo() {
      let params = {
        id_empleado: localStorage.getItem("id"),
      };
      this.loading = true;
      service
        .ordenes_trabajo(params)
        .then((response) => {
          this.loading = false;
          if (response.data.response_flag == 1) {
            //Si es correcto se guardan las variables del empleado
            this.ordenes = response.data.response;
            //console.log(orden_trabajo);
          } else {
            this.errored = true;
            this.message =
              "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
            setTimeout(() => (this.errored = false), 1000);
          }
        })
        .catch(() => {
          this.loading = false;
          this.errored = true;
          this.message =
            "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
          setTimeout(() => (this.errored = false), 1000);
        });
    },
    mueveReloj() {
      let momentoActual = new Date();
      let hora = momentoActual.getHours();
      let minuto = momentoActual.getMinutes();
      let segundo = momentoActual.getSeconds();

      hora = hora > 9 ? hora : "0" + hora;
      minuto = minuto > 9 ? minuto : "0" + minuto;
      segundo = segundo > 9 ? segundo : "0" + segundo;
      let tiempo = hora > 11 ? "PM" : "AM";

      let horaImprimible =
        hora + " : " + minuto + " : " + segundo + " " + tiempo + "   ";

      this.hora = horaImprimible;
      setTimeout(() => {
        this.mueveReloj();
      }, 1000);
    },
    contarTiempo() {
      if (this.bandera_contar) {
        this.segundos++;
        setTimeout(() => {
          this.contarTiempo();
        }, 1000);
      }
    },
    evaluarItem(item) {
      this.orden_trabajo_seleccionada = item;
      this.dialog = true;
      this.titulo = "ORDEN DE TRABAJO";
      this.id_orden_trabajo = item.folio_orden_trabajo;
      this.Consultar_Pasos_Orden();
    },
    Consultar_Pasos_Orden() {
      let params = {
        id_orden_trabajo: this.id_orden_trabajo,
      };
      this.loading = true;
      service
        .pasos_orden_trabajo(params)
        .then((response) => {
          this.loading = false;
          if (response.data.response_flag == 1) {
            //Si es correcto se guardan las variables y se redirecciona al inicio
              this.$router.push("inicio");
          } else {
            this.errored = true;
            this.message =
              "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
            setTimeout(() => (this.errored = false), 1000);
          }
        })
        .catch(() => {
          this.loading = false;
          this.errored = true;
          this.message =
            "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
          setTimeout(() => (this.errored = false), 1000);
        });
    },
    EnviarEvaluacion(){
      //Cerramos el formulario
      this.dialog_form = false;
      let params = {
        orden: this.id_orden_trabajo,
        cantidad_ok: this.cantidad_ok,
        cantidad_pnc: this.cantidad_pnc,
        motivo_cierre : this.motivo_cierre,
        tiempo : this.segundos,
        id_empleado: localStorage.getItem("id"),
      };
      service
        .evaluar(params)
        .then((response) => {
          this.loading = false;
          if (response.data.response_flag == 1) {
            //Si es correcto se guardan las variables del empleado 
            this.dialog = false;
            this.dialog_form = false;
            //Llamamos a la funcion que nos trae la ordenes de trabajo
            this.consultar_ordenes_trabajo();
            this.segundos = 0;
            //console.log(orden_trabajo);
          } else {
            this.errored = true;
            this.message =
              "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
            setTimeout(() => (this.errored = false), 1000);
          }
        })
        .catch(() => {
          this.loading = false;
          this.errored = true;
          this.message =
            "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
          setTimeout(() => (this.errored = false), 1000);
        });
    },
  },
};
</script>