import axios from "axios";

// const URL_SERVIDOR = "http://127.0.0.1:8000/";
const URL_SERVIDOR = "https://spe.backend.sdib.com.mx/public/";
const API_URL =  URL_SERVIDOR +  "api/";

class SPE {

    //Functiones de toma de datos
    login(params) {
        return axios.post(API_URL + "login", params);
    }
    ordenes_trabajo(params){
        return axios.post(API_URL + "ordenes_trabajo", params);
    }

    pasos_orden_trabajo(params){
        return axios.post(API_URL + "ordenes_trabajo_pasos", params);
    }

    evaluar(params){
        return axios.post(API_URL + "ordenes_trabajo_evaluar", params);
    }

}

export default new SPE();