<template>
  <v-app id="session">
    <div v-if="errored">
      <v-alert :type="type">
        {{ message }}
      </v-alert>
    </div>

    <v-container id="signinup-form" class="fill-height">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="5">
          <v-card class="elevation-24 card" :loading="loading">
            <template slot="progress">
              <v-progress-linear
                color="primary"
                height="7"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-window v-model="step">
              <!-- Inicio de Sesión-->
              <v-window-item :value="1">
                <v-row md="12" class="vcenter" :class="`${bgColor}`">
                  <!-- <v-col cols="12" md="4" class="vcenter" :class="`${bgColor}`"> -->
                    <div>
                      <v-layout mt-3 mb-4 column align-center>
                        <v-flex>
                            <v-img height="200" width="510" contain :src="require('@/assets/logos/trifuego_logo.jpg')"></v-img>
                        </v-flex>
                      </v-layout>
                      
                    </div>
                </v-row>
                <v-row md="12" class="pt-6 pb-6" :class="`${bgColor}`">
                  <v-col cols="12" md="12"> 
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-card-text class="text-center">
                        <h1 
                          class="text-center display-1 mb-10 text-center"
                          :class="`${bgColor} white--text`"
                        >
                          Iniciar sesión
                        </h1>
                        <v-text-field
                          v-model="nomina"
                          label="Numero de Nomina:"
                          append-icon="mdi-account"
                          type="number"
                          class="text-white"
                          v-on:keyup.enter="iniciar_sesion"
                          :rules="rules_login"
                          color="blanco"
                          
                        />
                        <div class="text-center mt-6">  
                          <v-btn
                            large
                            color="warning"
                            dark
                            @click="iniciar_sesion"
                            class=" font-weight-bold"                            
                            >Entrar</v-btn
                          >
                        </div>
                      </v-card-text>
                    </v-form>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "trifuego",
    },
    expressColor: {
      type: String,
      default: "error",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    footer: {
      inset: true,
    },
    step: 1,
    login_password_show: false,
    register_show_password: false,
    register_show_confirm_password: false,
    message: "",
    valid: false,
    errored: false,
    loading: false,
    cliente: {
      nombre: null,
      contrasena: null,
      correo: null,
      cliente_id_luthue: null,
    },
    contrasena: null,
    correo: null,
    nomina: null,
    type: "error",
    rules_login: [
      (v) => !!v || "Campo Requerido",
      (v) => v > 0 || "Solo se aceptan valores enteros",
    ],
  }),
  mounted() {},
  methods: {
    iniciar_sesion (){
        if (this.$refs.form.validate()) {
          this.loading = true;

           localStorage.setItem("id" ,1);
              localStorage.setItem("nombre" , "Josue Martin Soria Espinoza");
              localStorage.setItem("usuario" , "Sistemas");
              this.$router.push("inicio");
          /*
          service
          .login(params)
          .then((response) => {
            this.loading = false;
            if (response.data.response_flag == 1) {
              //Si es correcto se guardan las variables del empleado
              let empleado = response.data.response;
              localStorage.setItem("id" , empleado.id);
              localStorage.setItem("nombre" , empleado.nombre + ' ' + empleado.apellidoP + ' ' + empleado.apellidoM );
              localStorage.setItem("usuario" , empleado.usuario);
              this.$router.push("inicio");
            } else {
              this.errored = true;
              this.message = "Número de nomina no valido";
              setTimeout(() => (this.errored = false), 1000);
            }
          })
          .catch(() => {
            this.loading = false;
            this.errored = true;
            this.message =
              "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
            setTimeout(() => (this.errored = false), 1000);
          });
          */
        }
    }
  },
};
</script>

<style >
  .text-white input {
      color: rgb(255, 255, 255) !important;
    }
</style>